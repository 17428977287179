import { HorizontalLine, Rectangle } from '@arvesta-websites/icons';
import React from 'react';

type TObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'primary-with-background';

export type Config = {
  breadcrumb: {
    display: boolean;
  };
  googleSiteVerification: string;
  footer: {
    byArvesta: boolean;
    svg?: {
      Desktop: React.FC<{ className: string }>;
      Mobile: React.FC<{ className: string }>;
      Tablet: React.FC<{ className: string }>;
    };
    version: 'V1' | 'V2';
    differentLogo?: boolean;
  };
  header: {
    height: string;
    mobileLogo?: boolean;
  };
  NewsletterHeader: {
    SVG: React.FC<TObject>;
    className?: string;
    title?: { class: string };
  };
  ContactFormHeading: React.FC<TObject>;
  button: {
    ctaGroup?: ButtonVariant;
    ctaBanner?: {
      image: ButtonVariant;
      text: ButtonVariant;
    };
    dealerOverview?: {
      navigation?: ButtonVariant;
      details?: ButtonVariant;
    };
    heroSection?: ButtonVariant;
    infoSection?: ButtonVariant;
    shortBanner?: ButtonVariant;
    duoBanner?: ButtonVariant;
    infoBlock?: ButtonVariant;
    infoBlockColumns?: ButtonVariant;
  };
  mapSearchRadius: number;
  heroBanner: {
    mask: boolean;
  };
  CtaMask: React.FC<TObject>;
  eventsContactFormName: string;
  searchContactFormName: string;
};

const defaultConfig: Config = {
  ContactFormHeading: HorizontalLine,
  CtaMask: Rectangle,
  NewsletterHeader: {
    SVG: () => null,
    className: 'black',
  },
  breadcrumb: {
    display: true,
  },
  button: {
    ctaBanner: {
      image: 'primary',
      text: 'primary-with-background',
    },
    ctaGroup: 'primary',
    dealerOverview: {
      details: 'primary',
      navigation: 'tertiary',
    },
    duoBanner: 'primary',
    heroSection: 'primary',
    infoBlock: 'primary',
    infoBlockColumns: 'primary',
    infoSection: 'primary-with-background',
    shortBanner: 'primary',
  },
  eventsContactFormName: 'contact-events',
  footer: {
    byArvesta: true,
    version: 'V2',
  },
  googleSiteVerification: '',
  header: {
    height: '76px',
    mobileLogo: false,
  },

  // in meters!!
  heroBanner: {
    mask: false,
  },

  mapSearchRadius: 10000,
  searchContactFormName: 'contact-search',
};

export default defaultConfig;
